import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import BrandCTA from '../../../components/brand-cta'
import { withPrefix } from "gatsby"
import ExternalLink from '../../../components/external-link'
import ActiveLink from '../../../components/active-link'
import Hero from '../../../components/hero'
import callout from '../../../images/Puberty_Ages_Callout.png'
import callout2 from '../../../images/What_Is_CPP_Callout.png'

const metaTags = {
  description: 'Learn about your loved one\'s condition.',
  keywords: 'Homepage',
  title: 'What Is CPP?'
}

const externalLinksMetaTags = {
  category: 'External Link',
  action: 'Click',
  label: 'Deerfield Homepage',
}

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>What is central precocious puberty (CPP)?</h1>
      </Col>
    </Row>
    <Row>
      <Col md={6} xs={12}>
        <h2>Early puberty </h2>
        <p>During puberty, girls begin producing extra estrogen in the ovaries and boys begin producing extra testosterone in the testicles. These hormones produce the physical and emotional changes associated with puberty, such as breast development in girls and enlarged testicles and penis in boys.</p>
      </Col>
      <Col xs={12} md={6}>
          <img
            src={callout}
            alt="CPP is when puberty begins before age 8 for girls age 9 for boys"
            title="CPP is when puberty begins before age 8 for girls age 9 for boys"
          />
      </Col>
    </Row>
    <Row>
      <Col md={6} xs={12}>
        <h2>Your child’s healthcare professional should do tests to confirm a diagnosis of <strong>CPP</strong> before beginning treatment </h2>
        <p>When certain hormones are released too soon, a child can enter puberty earlier than he or she should. This may be the result of a condition called <strong>central precocious puberty (CPP)</strong>. There is no direct cause for CPP in most cases, but in rare cases it may be due to an underlying medical condition.</p>
      </Col>
      <Col xs={12} md={6}>
          <img
            src={callout2}
            alt="CPP occurs in 1 out of every 5000 to 10000 children"
            title="CPP occurs in 1 out of every 5000 to 10000 children"
          />
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
      <BrandCTA
          href={'/patients/caregiver-signup'}
          LinkCaption="Order an all-in-one caregiver's kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP"
        >
          Order an all-in-one caregiver's kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          ExternalLink
          href={withPrefix('/pdfs/SP-05715_SLA_Grady_Bear_Storybook_-_DIGITAL_final.pdf')}
          LinkCaption="Download storybook that makes CPP easy for kids to understand - what is cpp"
        >
          Download a storybook that helps make CPP easier for kids to understand
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
