

import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import EventEmitter from '../../lib/emitter'
import VideoCollection from '../../lib/videos/consumer-videos'
import { navigate } from "gatsby"

import img1 from '../../images/hero/SLA-hero-1.png';
import img2 from '../../images/hero/SLA-hero-2.png';
import img3 from '../../images/hero/SLA-hero-3.png';
import img4 from '../../images/hero/SLA-hero-4.png';
import imgGeraldineLizzy from '../../images/hero/SLA-hero-geraldine-lizzy.png';

import img1Mobile from '../../images/hero/SLA-hero-1-mobile.png';
import img2Mobile from '../../images/hero/SLA-hero-2-mobile.png';
import img3Mobile from '../../images/hero/SLA-hero-3-mobile.png';
import img4Mobile from '../../images/hero/SLA-hero-4-mobile.png';
import imgGeraldineLizzyMobile from '../../images/hero/SLA-hero-geraldine-lizzy-mobile.png';

import {
    isMobile
} from "react-device-detect";

import './hero.scss'

const handleHero1Click = () => {
    navigate('/patients/5-good-reasons-to-ask')
}
const handleHero2Click = () => {
    EventEmitter.emit(`onVideoOpenEvent-${VideoCollection[1].wistiaID}`, true)
}
const handleHero3Click = () => {
    EventEmitter.emit(`onVideoOpenEvent-${VideoCollection[2].wistiaID}`, true)
}
const handleHero4Click = () => {
    navigate('/patients/caregiver-signup')
}
const handleGeraldineLizzyHeroClick = () => {
    EventEmitter.emit(`onVideoOpenEvent-${VideoCollection[3].wistiaID}`, true)
}


//For customizations
//http://react-responsive-carousel.js.org/storybook/index.html

class Hero extends Component {
    render() {
        return (
            <Carousel emulateTouch autoPlay infiniteLoop dynamicHeight showThumbs={false} showStatus={false} showArrows={false} interval={8000}>
                <div onClick={handleHero1Click}>
                    <img className='desktop-only' src={(isMobile) ? img1Mobile : img1} />
                </div>
                <div onClick={handleHero4Click}>
                    <img className='desktop-only' src={(isMobile) ? img4Mobile : img4} />
                </div>
                <div onClick={handleHero2Click}>
                    <img className='desktop-only' src={(isMobile) ? img2Mobile : img2} />
                </div>
                <div onClick={handleGeraldineLizzyHeroClick}>
                    <img className='desktop-only' src={(isMobile) ? imgGeraldineLizzyMobile : imgGeraldineLizzy} />
                </div>
            </Carousel>
        );
    }
};

export default Hero;
